<template>
    <div>
        <v-stepper
            v-if="projects.length"
            v-model="stepper"
            outlined
            flat
            class="box-shadow  white"
        >
            <v-stepper-header>
                <template v-for="step in steps">
                    <v-stepper-step
                        :key="`${step}-step`"
                        :complete="stepper > step"
                        :step="step"
                        complete-icon="fa-solid fa-check"
                        :editable="stepper == step"
                        edit-icon="fa-solid fa-pencil"
                    >
                        {{ $t(`builder.components.step`) }} {{ step }}
                    </v-stepper-step>

                    <v-divider v-if="step !== steps" :key="step"></v-divider>
                </template>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content
                    v-for="(step, i) in projects"
                    :key="`${i + 1}-content`"
                    :step="i + 1"
                >
                    <step-image
                        v-if="category !== 'recipe'"
                        :step-image.sync="edittingImage"
                        :steps="step"
                    ></step-image>
                    <project-description
                        :project-description.sync="edittingDescription"
                        :description="edittingDescription"
                        :label="
                            $t(`builder.components.stepDescription`, {
                                step: stepper,
                            })
                        "
                    ></project-description>
                    <v-btn
                        depressed
                        dense
                        dark
                        color="primary"
                        @click="saveStep(i + 1)"
                    >
                        {{ $t(`builder.actions.save`) }}
                    </v-btn>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
        <div class="mt-10">
            <v-btn
                depressed
                dense
                color="primary"
                class="my-2"
                :disabled="stepperInvalid && category !== 'recipe'"
                @click="addStep()"
            >
                {{ $t(`builder.actions.addStep`) }}
                <v-icon class="ml-2" size="20">
                    fa-solid fa-plus
                </v-icon>
            </v-btn>

            <step-chips
                v-if="steps"
                class="my-2"
                :stepper.sync="stepper"
                :steps="steps"
                @remove-step="removeStep($event)"
            ></step-chips>
        </div>
    </div>
</template>

<script>
import ProjectDescription from "@/components/Builder/ProjectDescription.vue";
export default {
    name: "Stepper",

    props: {
        projectSteps: {
            required: true,
            type: Array,
        },
        category: {
            required: false,
            type: String,
            default: "project",
        },
    },

    components: {
        ProjectDescription,
        StepChips: () => import("@/components/Builder/StepChips.vue"),
        StepImage: () => import("@/components/Builder/StepImage.vue"),
    },

    data() {
        return {
            stepper: 1,
            steps: 0,
            edittingDescription: null,
            edittingImage: null,
            projects: [],
            description: null,
        };
    },

    computed: {
        stepperInvalid() {
            const data = this.projects;
            for (let i = 0; i < data.length; i++) {
                if (data[i].description === null) {
                    return true;
                }
                if (data[i].image_url === null) {
                    return true;
                }
            }
            return false;
        },
    },

    methods: {
        saveStep(step) {
            let data = {
                description: this.edittingDescription,
                image_url: this.edittingImage,
            };

            this.projects.splice(this.stepper - 1, 1, data);
            this.$emit("update:update-steps", this.projects);
        },

        addStep() {
            this.projects.push({
                description: "",
                image_url: null,
            });

            this.steps = this.steps + 1;
        },

        removeStep(event) {
            this.steps = this.steps - 1;
            this.stepper = event - 1;
            this.projects.splice(this.stepper, 1);
            this.$emit("update:update-steps", this.projects);
        },
    },

    watch: {
        stepper(newVal) {
            this.edittingDescription = this.projects[newVal - 1].description;
            this.edittingImage = this.projects[newVal - 1].image_url;
        },
    },

    created() {
        this.projects = [...this.projectSteps];
        this.edittingDescription = this.projects[0].description;
        this.edittingImage = this.projects[0].image_url;
        this.steps = this.projects.length;
        this.$emit("update:update-steps", this.projects);
    },
};
</script>

<style scoped>
.box-shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
        rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
}
</style>
