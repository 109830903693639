<template>
    <v-row class="mt-4" justify="center">
        <v-col v-if="action == 1" cols="12" sm="10" md="8" lg="6">
            <transition name="fade-transform" mode="out-in">
                <v-card
                    v-if="projectStatus_Success"
                    flat
                    class="builder__container"
                    color="white"
                >
                    <v-card-text>
                        <project-name
                            :project-name.sync="project.title"
                            :in-valid.sync="invalidName"
                            :name="project.title"
                            :label="$t(`builder.components.title`)"
                        ></project-name>
                        <select-project-media
                            :file.sync="media"
                            :file-type.sync="fileType"
                            :in-valid.sync="invalidMedia"
                            :project="project"
                        ></select-project-media>
                        <project-description
                            v-if="showDescription"
                            :project-description.sync="project.description"
                            :in-valid.sync="invalidDescription"
                            :description="project.description"
                            :label="$t(`builder.components.description`)"
                        ></project-description>
                        <add-ingredients
                            class="mt-6"
                            :list.sync="project.ingredients"
                            :ingredients="project.ingredients"
                        ></add-ingredients>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            depressed
                            x-large
                            dark
                            outlined
                            color="primary darken-3"
                            :disabled="projectInvalid"
                            @click="next()"
                        >
                            {{ $t(`builder.actions.next`) }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </transition>
        </v-col>
        <v-col v-if="action == 2" cols="12" sm="10" md="8" lg="6">
            <transition name="fade-transform" mode="out-in">
                <v-card flat color="appBackground">
                    <v-card-text>
                        <edit-stepper
                            :update-steps.sync="projectSteps"
                            :project-steps="project.steps"
                        ></edit-stepper>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            depressed
                            x-large
                            dark
                            outlined
                            color="primary darken-3"
                            :disabled="stepsInvalid"
                            @click="next()"
                        >
                            {{ $t(`builder.actions.finish`) }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </transition>
        </v-col>
        <v-col v-if="action == 3" cols="12" sm="10" md="8" lg="6">
            <transition name="fade-transform" mode="out-in">
                <v-card
                    flat
                    class="d-flex flex-column align-center centered"
                    color="appBackground"
                >
                    <v-card-text class="d-flex flex-column align-center">
                        <hero :size="140"></hero>
                        <div class="mt-6">
                            <h2>{{ $t(`builder.finishTxt`) }}</h2>
                        </div>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center">
                        <v-btn
                            x-large
                            dark
                            outlined
                            color="primary darken-3"
                            @click="updateAsDraft()"
                        >
                            {{ $t(`builder.actions.save`) }}
                        </v-btn>
                        <v-btn
                            depressed
                            x-large
                            dark
                            outlined
                            color="primary darken-3"
                            @click="updateAsPublish()"
                        >
                            {{ $t(`builder.actions.publish`) }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </transition>
        </v-col>
    </v-row>
</template>

<script>
import { withAsync } from "@/helpers/withAsync.js";
import { getProject, updateProject } from "@/api/projectsApi.js";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus.js";
import { apiStatus } from "@/api/constants/apiStatus.js";
import { mapState } from "vuex";

import ProjectName from "@/components/Builder/ProjectName.vue";
import ProjectDescription from "@/components/Builder/ProjectDescription.vue";
import AddIngredients from "@/components/Builder/AddIngredients.vue";
import Hero from "@/components/Heroes/Hero.vue";
import SelectProjectMedia from "@/components/Builder/SelectProjectMedia.vue";
import EditStepper from "@/components/Builder/EditStepper.vue";
export default {
    name: "EditProject",

    components: {
        ProjectName,
        ProjectDescription,
        AddIngredients,
        SelectProjectMedia,
        Stepper: () => import("@/components/Builder/Stepper.vue"),
        StepChips: () => import("@/components/Builder/StepChips.vue"),
        StepImage: () => import("@/components/Builder/StepImage.vue"),
        EditStepper,
        Hero,
    },

    data() {
        return {
            fileType: null,
            project: {},
            projectSteps: [],
            media: null,
            action: 1,
            invalidMedia: false,
            invalidName: false,
            invalidDescription: false,
            buildType: null,

            projectStatus: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed(["projectStatus"]),
        ...mapState({
            user_role: (state) => state.auth.user.role,
        }),

        showDescription() {
            if (this.fileType == "youtube_url") {
                return false;
            }
            return true;
        },

        projectInvalid() {
            return (
                this.invalidDescription ||
                this.invalidMedia ||
                this.invalidName ||
                !this.project.ingredients?.length > 0
            );
        },

        stepsInvalid() {
            let valid = false;
            for (let i = 0; i < this.projectSteps.length; i++) {
                let step = this.projectSteps[i];
                if (!step.description || step.description === "") {
                    valid = true;
                    break;
                }
                if (!step.image_url) {
                    valid = true;
                    break;
                }
            }

            return valid;
        },
    },

    methods: {
        async handleProject() {
            this.projectStatus = apiStatus.Pending;
            const payload = {
                id: this.$route.params.id,
            };
            const { response, error } = await withAsync(getProject, payload);

            if (error) {
                this.projectStatus = apiStatus.Error;
                return;
            }

            this.project = response.data.data;
            this.media = this.project.image_url || this.project.youtube_url;
            this.projectSteps = this.project?.steps;

            this.projectStatus = apiStatus.Success;
        },
        async createWithImage(status) {
            let data = new FormData();
            let description = [];
            if (this.fileType == "image_url") {
                this.project.image_url = this.media;
            }
            data.append("title", this.project.title);
            data.append("image_url", this.project.image_url);
            data.append("description", this.project.description);

            data.append(
                "number_of_ingredients",
                this.project.ingredients.length
            );
            this.project.ingredients.forEach((item, idx) => {
                data.append(`ingredients_${idx + 1}`, item);
            });

            data.append("status", status);

            data.append("number_of_steps", this.projectSteps.length);
            this.projectSteps.forEach((item, idx) => {
                data.append(`step_description_${idx + 1}`, item.description);
                data.append(`step_image_${idx + 1}`, item.image_url);
            });

            const paylaod = {
                id: this.$route.params.id,
                data: data,
            };

            let url = `${this.user_role}/project/${this.$route.params.id}`;

            const { response, error } = await withAsync(
                updateProject,
                paylaod,
                url
            );

            if (error) {
                return;
            }
        },
        async createWithVideo(status) {
            let payload;
            if (this.fileType == "youtube_url") {
                this.project.youtube_url = this.media;
            }
            payload = {
                title: this.project.title,
                youtube_url: this.project.youtube_url,
                ingredients: this.project.ingredients,
                status: status,
            };

            const paylaod = {
                id: this.$route.params.id,
                data: payload,
            };

            let url = `${this.user_role}/project/${this.$route.params.id}`;

            const { response, error } = await withAsync(
                updateProject,
                paylaod,
                url
            );

            if (error) {
                return;
            }
        },
        next() {
            if (this.fileType == "youtube_url") {
                this.action = 3;
                return;
            }
            if (this.$route.params.type == 4) {
                this.action = 3;
                return;
            }

            if (
                this.fileType === "image_url" &&
                this.project.steps.length === 0
            ) {
                this.project.steps = [
                    {
                        description: null,
                        image_url: null,
                    },
                ];


                // this.projectSteps = this.project.steps;
            }
            this.action++;
        },
        updateAsDraft() {
            let status = 0;
            this.fileType == "image_url"
                ? this.createWithImage(status)
                : this.createWithVideo(status);
            this.$router.push({ name: "MyProjects" });
        },
        updateAsPublish() {
            let status = 1;
            this.fileType == "image_url"
                ? this.createWithImage(status)
                : this.createWithVideo(status);
            this.$router.push({ name: "MyProjects" });
        },
    },

    watch: {},

    created() {
        this.handleProject();
    },
};
</script>
